import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { DataViewFactoryService } from '@x/common/data';
import { SageItemSyncClient, SageItemSyncDetailProvider } from '@x/sage/client';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'x-sage-stock-item-sync-panel',
  templateUrl: 'sage-stock-item-sync-panel.component.html',
  styleUrls: ['sage-stock-item-sync-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SageStockItemSyncPanelComponent implements OnInit, OnDestroy {
  @Input()
  set sku(sku: string | null) {
    this._sku.next(sku);
  }

  _sku = new BehaviorSubject<string | null>(null);

  view = this.viewFactory.resolveView(SageItemSyncDetailProvider, {
    idSubject: this._sku,
    entityName: 'Sage Stock Item Sync',
  });

  lotsControl = new FormArray<any>([]);

  formGroup = new FormGroup({
    enabled: new FormControl<boolean>(false),
  });

  private _destroy$ = new Subject<void>();

  constructor(
    private readonly viewFactory: DataViewFactoryService,
    private readonly changeRef: ChangeDetectorRef,
    private readonly client: SageItemSyncClient,
  ) {}

  ngOnInit(): void {
    this.view.connect();
    this.view
      .stateChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.formGroup.reset(
          {
            enabled: this.view.data?.enabled ?? false,
          },
          { emitEvent: false },
        );
        this.changeRef.markForCheck();
      });

    this.formGroup.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((value) => {
      this.view
        .observeMutation((sku) => this.client.update({ sku, enabled: value.enabled ?? true }))
        .subscribe();
    });

    this.formGroup.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      const form = this.formGroup.getRawValue();
      this.view
        .observeMutation(
          (sku: string) =>
            this.client.update({
              enabled: form.enabled ?? false,
              sku,
            }),
          {
            label: 'Update Sage Order Sync',
          },
        )
        .subscribe();
    });
  }

  ngOnDestroy(): void {
    this.view.disconnect();
    this._sku.complete();
    this._destroy$.next();
    this._destroy$.complete();
  }

  sync() {
    // this.view
    //   .observeMutation((sku: string) => this.client.sync(sku), {
    //     label: 'Sync Sage Order',
    //   })
    //   .subscribe();
  }

  update(enabled: boolean) {
    this.view
      .observeMutation((sku: string) => this.client.update({ enabled, sku }), {
        label: 'Update Sage Order Sync',
      })
      .subscribe();
  }

  refresh() {
    this.view.refresh();
  }
}
