import { NgModule } from '@angular/core';
import { EcommerceAdminCoreModule } from '../core/ecommerce-admin-core.module';
import { SageOrderSyncPanelComponent } from './components/sage-order-sync-panel/sage-order-sync-panel.component';
import { SageOrderSyncPayloadDialogComponent } from './components/sage-order-sync-payload-dialog/sage-order-sync-payload-dialog.component';
import { SageStockItemSyncPanelComponent } from './components/sage-stock-item-sync-panel/sage-stock-item-sync-panel.component';
import { SageOrderSyncDialogService } from './services/sage-order-sync-dialog.service';

@NgModule({
  imports: [EcommerceAdminCoreModule],
  declarations: [
    SageStockItemSyncPanelComponent,
    SageOrderSyncPanelComponent,
    SageOrderSyncPayloadDialogComponent,
  ],
  exports: [
    SageStockItemSyncPanelComponent,
    SageOrderSyncPanelComponent,
    SageOrderSyncPayloadDialogComponent,
  ],
  providers: [SageOrderSyncDialogService],
})
export class SageSharedModule {}
