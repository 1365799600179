import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DataViewFactoryService } from '@x/common/data';
import { SageOrderSyncClient, SageOrderSyncDetailProvider } from '@x/sage/client';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'x-sage-order-sync-panel',
  templateUrl: 'sage-order-sync-panel.component.html',
  styleUrls: ['sage-order-sync-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SageOrderSyncPanelComponent implements OnInit, OnDestroy {
  @Input()
  set number(number: string | null) {
    this._number.next(number);
  }

  _number = new BehaviorSubject<string | null>(null);

  view = this.viewFactory.resolveView(SageOrderSyncDetailProvider, {
    idSubject: this._number,
    entityName: 'Sage Order Sync',
  });

  private _destroy$ = new Subject<void>();

  constructor(
    private readonly viewFactory: DataViewFactoryService,
    private readonly changeRef: ChangeDetectorRef,
    private readonly sageOrderSyncClient: SageOrderSyncClient,
  ) {}

  ngOnInit(): void {
    this.view.connect();
    this.view
      .stateChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.changeRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.view.disconnect();
    this._number.complete();
    this._destroy$.next();
    this._destroy$.complete();
  }

  sync() {
    this.view
      .observeMutation((number: string) => this.sageOrderSyncClient.sync(number), {
        label: 'Sync Sage Order',
      })
      .subscribe();
  }

  update(enabled: boolean) {
    this.view
      .observeMutation((number: string) => this.sageOrderSyncClient.update({ enabled, number }), {
        label: 'Update Sage Order Sync',
      })
      .subscribe();
  }

  delete() {
    this.view
      .observeMutation((number: string) => this.sageOrderSyncClient.delete(number), {
        label: 'Delete Sage Order Sync',
      })
      .subscribe();
  }

  refresh() {
    this.view.refresh();
  }
}
