<mat-expansion-panel class="mat-elevation-z0">
  <mat-expansion-panel-header>
    <mat-panel-title>Sage Sync</mat-panel-title>
    <mat-panel-description></mat-panel-description>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <table *ngIf="view.data as sage">
      <tbody>
        <tr>
          <th>SKU</th>
          <td>{{ sage.sku }}</td>
        </tr>
        <tr>
          <th>Name</th>
          <td>{{ sage.name }}</td>
        </tr>
        <tr>
          <th>Category</th>
          <td>{{ sage.category }}</td>
        </tr>
        <tr>
          <th>Description</th>
          <td style="white-space: pre-line">{{ sage.description }}</td>
        </tr>
        <tr>
          <th>Updated At</th>
          <!-- <td>{{ sage.updatedAt | datetime : 'medium' }}</td> -->
        </tr>
        <tr>
          <th>Created At</th>
          <!-- <td>{{ sage.createdAt | datetime : 'medium' }}</td> -->
        </tr>
      </tbody>
    </table>
  </ng-template>
  <mat-action-row [formGroup]="formGroup">
    <mat-spinner radius="20" *ngIf="view.fetchLoading || view.mutationLoading"></mat-spinner>
    <mat-slide-toggle formControlName="enabled">Enable Sync</mat-slide-toggle>
  </mat-action-row>
</mat-expansion-panel>
